import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { transformDate } from "utils/dayjs"
import useScrollArrows from "hooks/newUI/terminal/useScrollArrows"
import { toDecimalFormat } from "helpers/exchange"
import styles from "components/NewUI/Terminal/Bottom/OpenOrders/List/index.module.scss"
import HeadlineItem from "../HeadlineItem"
import Row from "../Row"

const Section: React.FC<{
	list: unknown[]
	arrNames: { headline: string; width: number; asociate: string }[]
	arrCount: number
	id: number
	isScroll?: boolean
	pairAmountPrecision: number
	pairPricePrecision: number
	setCurrentOpenOrderHistoryOrderID?: (id: string) => void
	currentOpenOrderHistoryOrderID?: string
}> = ({
	list,
	arrNames,
	arrCount,
	isScroll,
	id,
	pairAmountPrecision,
	pairPricePrecision,
	setCurrentOpenOrderHistoryOrderID,
	currentOpenOrderHistoryOrderID,
}) => {
	const { formatNumber } = useIntl()
	const namesArr = [...arrNames].splice(0, arrCount)
	const {
		ref,
		handleArrowLeftClick,
		showArrows,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
	} = useScrollArrows(undefined, false, false)

	return (
		<div
			className={`${styles.list__listWrapper}  ${
				showArrows ? styles.list__listWrapper___arrows : ""
			} ${isScroll ? styles.list__listWrapper___scroll : ""}`}
		>
			{isScroll ? (
				<>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.list__listButtonLeft} ${
							showArrows && showLeftArrow ? styles.list__listButtonLeft___show : ""
						}`}
					/>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.list__listButtonRight} ${
							showArrows && showRightArrow ? styles.list__listButtonRight___show : ""
						}`}
					/>
				</>
			) : null}

			<div
				ref={isScroll ? ref : undefined}
				className={`${styles.list__listContainer} ${
					isScroll ? styles.list__listContainer___scroll : ""
				}`}
			>
				<ul className={styles.list__headline}>
					{namesArr.map(({ headline, width }) => (
						<HeadlineItem
							key={`${id}${headline}-headline-item`}
							headline={headline}
							width={width}
						/>
					))}
				</ul>

				{list.length > 0 ? (
					<ul className={styles.list__items}>
						{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
						{list.map((item: any) => (
							<div key={`${id}${item.orderId}-list-item`}>
								<Row
									handleArrowLeftClick={handleArrowLeftClick}
									showArrows={showArrows}
									showLeftArrow={showLeftArrow}
									showRightArrow={showRightArrow}
									handleArrowRightClick={handleArrowRightClick}
									key={`${id}${item.orderId}-list-item`}
									item={item}
									id={id}
									isScroll={isScroll}
									namesArr={namesArr}
									pairAmountPrecision={pairAmountPrecision}
									pairPricePrecision={pairPricePrecision}
									setCurrentOpenOrderHistoryOrderID={setCurrentOpenOrderHistoryOrderID}
									currentOpenOrderHistoryOrderID={currentOpenOrderHistoryOrderID}
								/>

								<div
									key={`${id}${item.orderId}-list-item-dropdown`}
									className={styles.list__itemsDropdown}
									style={{
										marginTop: `-${
											item.trades.length > 0 ? 30 + 32 * item.trades.length : 30 + 32
										}px`,
									}}
								>
									<div className={styles.list__itemsDropdownHeader}>
										<p className={styles.list__itemsDropdownHeaderText}>Filled Value</p>

										<p className={styles.list__itemsDropdownHeaderText}>Filled Price</p>

										<p className={styles.list__itemsDropdownHeaderText}>Filled Q-ty</p>

										<p className={styles.list__itemsDropdownHeaderText}>Trading Fees</p>

										<p className={styles.list__itemsDropdownHeaderText}>Filled Time</p>

										<p className={styles.list__itemsDropdownHeaderText}>Transaction ID</p>
									</div>

									{item.trades.length > 0 ? (
										item.trades.map((trade: any) => {
											const [pairDest, pairSrc] = trade.pair_id.split("_")
											return (
												<div key={trade.id} className={styles.list__itemsDropdownItem}>
													<p className={styles.list__itemsDropdownItemText}>
														<span>
															{formatNumber(trade.amount2, {
																useGrouping: false,
																minimumFractionDigits: pairPricePrecision,
																maximumFractionDigits: pairPricePrecision,
															})}
														</span>
														<span className={styles.list__itemsDropdownItemCurrencyText}>
															{pairSrc}
														</span>
													</p>

													<p className={styles.list__itemsDropdownItemText}>
														<span>
															{formatNumber(trade.price, {
																useGrouping: false,
																minimumFractionDigits: pairPricePrecision,
																maximumFractionDigits: pairPricePrecision,
															})}
														</span>
														<span className={styles.list__itemsDropdownItemCurrencyText}>
															{pairSrc}
														</span>
													</p>

													<p className={styles.list__itemsDropdownItemText}>
														<span>
															{formatNumber(trade.amount1, {
																useGrouping: false,
																minimumFractionDigits: pairAmountPrecision,
																maximumFractionDigits: pairAmountPrecision,
															})}
														</span>
														<span className={styles.list__itemsDropdownItemCurrencyText}>
															{pairDest}
														</span>
													</p>

													<p className={styles.list__itemsDropdownItemText}>
														<span>
															{formatNumber(trade.fee_amount, {
																useGrouping: false,
																minimumFractionDigits: 8,
																maximumFractionDigits: 8,
															})}
														</span>
														<span className={styles.list__itemsDropdownItemCurrencyText}>
															{trade.fee_currency_id}
														</span>
													</p>

													<p className={styles.list__itemsDropdownItemText}>
														<span>{transformDate(trade.date).format("MM/DD/YYYY HH:mm:ss")}</span>
													</p>

													<p className={styles.list__itemsDropdownItemText}>
														<span>{trade.id}</span>
													</p>
												</div>
											)
										})
									) : (
										<div className={styles.list__itemsDropdownItem}>
											<p className={styles.list__itemsDropdownItemText}>
												<span>--</span>
											</p>

											<p className={styles.list__itemsDropdownItemText}>
												<span>--</span>
											</p>

											<p className={styles.list__itemsDropdownItemText}>
												<span>--</span>
											</p>

											<p className={styles.list__itemsDropdownItemText}>
												<span>--</span>
											</p>

											<p className={styles.list__itemsDropdownItemText}>
												<span>--</span>
											</p>

											<p className={styles.list__itemsDropdownItemText}>
												<span>--</span>
											</p>
										</div>
									)}
								</div>
							</div>
						))}
					</ul>
				) : null}
			</div>
		</div>
	)
}

export default observer(Section)
