import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { FormattedMessage } from "react-intl"
import messages from "messages/aboutUs"

import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"
import styles from "./index.module.scss"

const Question: FC = () => (
	<section className={styles.question}>
		<div className={styles.question__container}>
			<div className={styles.question__content}>
				<h3 className={styles.question__headline}><FormattedMessage {...messages.form_title} /></h3>

				<p className={styles.question__description}>
					<FormattedMessage {...messages.form_description} />
				</p>
			</div>

			<InternalLink className={styles.question__link} to={routes.support.request}>
				<FormattedMessage {...messages.form_btn_text} />
			</InternalLink>
		</div>
	</section>
)

export default observer(Question)
