import React from "react"
import { observer } from "mobx-react-lite"
// eslint-disable-next-line import/no-extraneous-dependencies
import Confetti from "react-confetti"

import { useMst } from "models/Root"
import doge from "assets/images/loot/doge.png"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import { routes } from "constants/routing"
import LootBox, { ELootBoxColor } from "components/LootBox"
import Header from "../Header"
import Button from "../Button"
import styles from "./RegWelcome.module.scss"

const RegWelcome: React.FC = () => {
	const {
		register: { isLoading, onSubmitFinishStep },
	} = useMst()
	const localeNavigate = useLocaleNavigate()
	const [opened, setOpened] = React.useState(false)

	const submitFinishStep = async () => {
		await onSubmitFinishStep()

		localeNavigate(routes.trade.root)
		setOpened(false)
	}

	return (
		<>
			<div className={styles.regWelcome}>
				<Header
					headline="Welcome to BTC-Alpha"
					description="Embark on your cryptocurrency journey with BTC-Alpha, your trusted gateway to the world of digital finance."
				/>

				<div className={styles.regWelcome__wrapper}>
					<Confetti
						width={window.innerWidth}
						height={window.innerHeight}
						numberOfPieces={100}
						gravity={0.03}
					/>

					<div className={styles.regWelcome__buttonWrapper}>
						<Button isLoading={isLoading} onClick={() => setOpened(true)} type="button">
							Start Trading
						</Button>
					</div>
				</div>
			</div>

			<LootBox
				isOpened={opened}
				onClose={() => setOpened(false)}
				color={ELootBoxColor.PURPLE}
				title={["Get your BTC Box", "for Registration"]}
				description="Grab your silver box and get 1000 USDT, 0.01 BTC, 500 free trading operations, a bonus to reduce trading fees for 1 month, and a random reward in DOGE, PEPE, TRX, or XRP."
				footer="Claim your crypto box by Registration. Box expires by 26 Oct 2024 00:00:00 UTC"
				submittedTitle={["Congratulation!", "You won 0.1 DOGE."]}
				submittedFooter="Your reward will be automatically credited to your balance within 6 hours."
				coinText="0.1 DOGE"
				coinLogoSrc={doge}
				onClaim={submitFinishStep}
			/>
		</>
	)
}

export default observer(RegWelcome)
