import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import messages from "messages/home"

import Security360Image from "assets/images/HomePage/Security360Image.webp"
import styles from "./index.module.scss"

const Security360: FC = () => (
	<div className={styles.security360}>
		<div className={styles.security360__content}>
			<div className={styles.security360__contentWrapper}>
				<h3 className={styles.security360__headline}><FormattedMessage {...messages.main_tub_name_2} /></h3>

				<p className={styles.security360__contentText}>
					<FormattedMessage {...messages.main_tub_content_description_2} />
				</p>
			</div>

			{/*<button className={styles.security360__button} type="button">*/}
			{/*	Learn more*/}
			{/*</button>*/}
		</div>

		<img
			className={styles.security360__svg}
			src={Security360Image}
			width="280"
			height="280"
			alt="360 Security"
		/>
	</div>
)

export default Security360
