import React, { useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import Input from "../Input"
import Header from "../Header"
import Button from "../Button"
import styles from "./RegPersonalForm.module.scss"
import CountrySelect from "../../CountrySelect/CountrySelect"
import commonMessages from "../../../../messages/common";

const RegPersonalForm: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		register: {
			resetRegisterPersonalState,
			onSubmitPersonalStep,
			isLoading,
			errors,
			personalFirstName,
			setPersonalLastName,
			personalLastName,
			setPersonalFirstName,
			countryCode,
			setCountryCode,
		},
		global: { locale },
	} = useMst()

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		onSubmitPersonalStep(formatMessage).then(() => null)
	}

	const handleCountryChange = useCallback((_: any, value: any) => {
		setCountryCode(value ?? "")
	}, [])

	useEffect(() => () => resetRegisterPersonalState(), [])

	return (
		<div className={styles.regPersonalForm}>
			<Header
				headline={formatMessage(commonMessages.almost_done)}
				description={formatMessage(commonMessages.almost_done_description)}
			/>

			<div className={styles.regPersonalForm__wrapper}>
				<form onSubmit={onSubmit}>
					<div className={styles.regPersonalForm__inputWrapper}>
						<Input
							id="reg-firstname"
							label={formatMessage(commonMessages.first_name)}
							handleChange={setPersonalFirstName}
							error={errors.personalFirstName}
							value={personalFirstName}
							autoFocus
							placeholder={formatMessage(commonMessages.first_name)}
						/>

						<Input
							id="reg-lastname"
							label={formatMessage(commonMessages.last_name)}
							handleChange={setPersonalLastName}
							error={errors.personalLastName}
							value={personalLastName}
							placeholder={formatMessage(commonMessages.last_name)}
						/>
					</div>

					<div className={styles.regPersonalForm__countryWrapper}>
						<CountrySelect
							value={countryCode}
							label={formatMessage(commonMessages.country_residence)}
							name="residence"
							locale={locale}
							onSelect={handleCountryChange}
							error={errors.countryCode}
						/>
					</div>

					<div className={styles.regPersonalForm__buttonWrapper}>
						<Button isLoading={isLoading} type="submit">
							{formatMessage(commonMessages.finish)}
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default observer(RegPersonalForm)
