import React, { useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import Input from "../Input"
import Header from "../Header"
import Button from "../Button"
import styles from "./RegJuridicalForm.module.scss"
import CountrySelect from "../../CountrySelect/CountrySelect"
import commonMessages from "../../../../messages/common";

const RegJuridicalForm: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		register: {
			companyID,
			setCompanyID,
			companyName,
			setCompanyName,
			beneficeFirstName,
			setBeneficeFirstName,
			beneficeLastName,
			setBeneficeLastName,
			beneficePosition,
			setBeneficePosition,
			resetRegisterJuridicalState,
			onSubmitJuridicalStep,
			isLoading,
			errors,
			countryCode,
			setCountryCode,
		},
		global: { locale },
	} = useMst()

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		onSubmitJuridicalStep(formatMessage).then(() => null)
	}

	const handleCountryChange = useCallback((_: any, value: any) => {
		setCountryCode(value ?? "")
	}, [])

	useEffect(() => () => resetRegisterJuridicalState(), [])

	return (
		<div className={styles.regJuridicalForm}>
			<Header
				headline={formatMessage(commonMessages.almost_done)}
				description={formatMessage(commonMessages.almost_done_description)}
			/>

			<div className={styles.regJuridicalForm__wrapper}>
				<form onSubmit={onSubmit}>
					<div className={styles.regJuridicalForm__inputTopWrapper}>
						<Input
							id="reg-company-name"
							label={formatMessage(commonMessages.company_name)}
							handleChange={setCompanyName}
							value={companyName}
							autoFocus
							placeholder={formatMessage(commonMessages.company_name)}
							error={errors.companyName}
						/>
					</div>

					<div className={styles.regJuridicalForm__inputWrapper}>
						<Input
							id="reg-company-id"
							label={formatMessage(commonMessages.company_id)}
							handleChange={setCompanyID}
							value={companyID}
							placeholder={formatMessage(commonMessages.enter_id)}
							error={errors.companyID}
						/>
					</div>

					<div className={styles.regJuridicalForm__countryWrapper}>
						<CountrySelect
							value={countryCode}
							label={formatMessage(commonMessages.company_jurisdiction)}
							name="companyJurisdiction"
							locale={locale}
							onSelect={handleCountryChange}
							error={errors.countryCode}
						/>
					</div>

					<div className={styles.regJuridicalForm__inputDoubleWrapper}>
						<Input
							id="reg-benefice-first-name"
							label={formatMessage(commonMessages.benefice_first_name)}
							handleChange={setBeneficeFirstName}
							value={beneficeFirstName}
							placeholder={formatMessage(commonMessages.first_name)}
							error={errors.beneficeFirstName}
						/>

						<Input
							id="reg-benefice-last-name"
							label={formatMessage(commonMessages.last_name)}
							handleChange={setBeneficeLastName}
							value={beneficeLastName}
							placeholder={formatMessage(commonMessages.last_name)}
							error={errors.beneficeLastName}
						/>
					</div>

					<div className={styles.regJuridicalForm__inputWrapper}>
						<Input
							id="reg-benefice-position"
							label={formatMessage(commonMessages.benefice_position)}
							handleChange={setBeneficePosition}
							value={beneficePosition}
							placeholder={formatMessage(commonMessages.position)}
							error={errors.beneficePosition}
						/>
					</div>

					<div className={styles.regJuridicalForm__buttonWrapper}>
						<Button isLoading={isLoading} type="submit">
							{formatMessage(commonMessages.finish)}
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default observer(RegJuridicalForm)
