import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { FormattedMessage } from "react-intl"

import messages from "messages/aboutUs"
import cardsImage from "assets/images/AboutUs/cardContent.webp"
import cardsItem from "assets/images/AboutUs/cards.webp"
import styles from "./index.module.scss"

const Cards: FC = () => (
	<section className={styles.cards}>
		<h3 className="visually-hidden">Crypto Cards</h3>

		<div className={styles.cards__container}>
			<div className={styles.cards__content}>
				<h4 className={styles.cards__title}><FormattedMessage {...messages.revolutionize} /></h4>

				<p className={styles.cards__description}>
					<FormattedMessage {...messages.cryptocurrency} />
				</p>
			</div>

			<div className={styles.cards__imageWrapper}>
				<img
					className={styles.cards__image}
					src={cardsImage}
					alt="Cards"
					width={284}
					height={430}
				/>

				<img
					className={styles.cards__imageItem}
					src={cardsItem}
					alt="card"
					width={454}
					height={326}
				/>
			</div>
		</div>
	</section>
)

export default observer(Cards)
