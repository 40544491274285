/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { CSSProperties, useEffect, useId, useState } from "react"
import cn from "classnames"

import Modal from "components/UI/Modal"

import styles from "styles/components/LootBox.module.scss"

import img_Noise from "assets/images/loot/noise.png"
import img_Light from "assets/images/loot/box_light.png"
import img_LightPurple from "assets/images/loot/box_light_purple.png"
import img_LightGray from "assets/images/loot/box_light_gray.png"
import img_LightYellow from "assets/images/loot/box_light_yellow.png"
import img_Shadow from "assets/images/loot/box_shadow.png"
import img_BoxPurple from "assets/images/loot/box_purple.webp"
import img_BoxGray from "assets/images/loot/box_gray.webp"
import img_BoxYellow from "assets/images/loot/box_yellow.webp"
import img_CoinBitcoin from "assets/images/loot/bitcoin.svg"
import img_CoinEthereum from "assets/images/loot/ethereum.svg"
import img_CoinTether from "assets/images/loot/tether.svg"
import img_CoinUsdc from "assets/images/loot/usdc.svg"

export enum ELootBoxColor {
	PURPLE = "purple",
	GRAY = "gray",
	YELLOW = "yellow",
}

const classes: Record<ELootBoxColor, string> = {
	[ELootBoxColor.PURPLE]: styles.purple,
	[ELootBoxColor.GRAY]: styles.gray,
	[ELootBoxColor.YELLOW]: styles.yellow,
}

const lights: Record<ELootBoxColor, string> = {
	[ELootBoxColor.PURPLE]: img_LightPurple,
	[ELootBoxColor.GRAY]: img_LightGray,
	[ELootBoxColor.YELLOW]: img_LightYellow,
}

const boxes: Record<ELootBoxColor, string> = {
	[ELootBoxColor.PURPLE]: img_BoxPurple,
	[ELootBoxColor.GRAY]: img_BoxGray,
	[ELootBoxColor.YELLOW]: img_BoxYellow,
}

const coins = [img_CoinBitcoin, img_CoinUsdc, img_CoinEthereum, img_CoinTether, img_CoinEthereum]

const LIGHT_BLINK_SPEED_MS = 1000
const COINS_SPEED_MS = 10000
const COINS_HIDE_DELAY_MS = 100
const COINS_HIDE_SPEED_MS = 300
const COINS_TWITCHING_DELAY_MAX_MS = 2000
const COINS_TWITCHING_SPEED_MS = 2000
const JUMP_SPEED_MS = 2000
const ROTATE_SPEED_MS = 2000
const ROTATE_COUNT: number | "infinite" = 1
const BUTTON_BACK_SPEED_MS = 3000

type TMultilineText = string | string[]

const LinesToText: React.FC<{ text?: TMultilineText }> = ({ text }) => {
	const id = useId()
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{text instanceof Array
				? text.map((line, index, arr) => (
						<React.Fragment key={`text-${id}-line-${index.toString()}`}>
							{line}
							{index < arr.length - 1 && <br />}
						</React.Fragment>
				  ))
				: text}
		</>
	)
}

type TProps = {
	title?: TMultilineText
	description?: TMultilineText
	footer?: TMultilineText
	color?: ELootBoxColor
	submittedTitle?: TMultilineText
	submittedFooter?: TMultilineText
	coinLogoSrc?: string
	coinText?: string
	isOpened?: boolean
	onClose?: () => void
	onClaim?: () => void
}

const LootBox: React.FC<TProps> = ({
	title,
	description,
	footer,
	color = ELootBoxColor.GRAY,
	submittedTitle,
	submittedFooter,
	coinLogoSrc,
	coinText,
	isOpened,
	onClose,
	onClaim,
}) => {
	const [isSubmitted, setSubmitted] = useState<boolean>(false)

	const onSubmit = () => (!isSubmitted ? setSubmitted(true) : onClaim?.())

	useEffect(() => setSubmitted(false), [isOpened])

	return (
		<Modal
			headerDisable
			className={cn(styles.opening_box, classes[color], { [styles.submitted]: isSubmitted })}
			isOpen={!!isOpened}
			onClose={() => onClose?.()}
		>
			<button type="button" title="Close" className={styles.close} onClick={onClose}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M18 6L6 18M6 6L18 18"
						stroke="black"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			<div className={styles.title}>
				<LinesToText text={isSubmitted ? submittedTitle : title} />
			</div>
			{!isSubmitted && (
				<div className={styles.description}>
					<LinesToText text={description} />
				</div>
			)}
			<div className={styles.body} />
			<button
				type="button"
				className={styles.action}
				style={{ "--back": `${BUTTON_BACK_SPEED_MS}ms` } as CSSProperties}
				onClick={onSubmit}
			>
				{isSubmitted ? "Claim your Reward" : "Open Box"}
			</button>
			<div className={styles.expire}>
				<LinesToText text={isSubmitted ? submittedFooter : footer} />
			</div>
			<div
				className={styles.box_computed}
				style={
					{
						"--light": `${LIGHT_BLINK_SPEED_MS}ms`,
						"--jump": `${JUMP_SPEED_MS}ms`,
					} as CSSProperties
				}
			>
				<img className={styles.shadow} src={img_Shadow} alt="box-shadow" />
				<img className={styles.box} src={boxes[color]} alt="box" />
				<img className={styles.light} src={lights[color]} alt="box-light" />
				<img className={cn(styles.light, styles.blinking)} src={img_Light} alt="box-light" />
				<div
					className={cn(styles.coins, { [styles.hidden]: isSubmitted })}
					style={
						{
							"--speed": `${COINS_SPEED_MS}ms`,
							"--hide-speed": `${COINS_HIDE_SPEED_MS}ms`,
							"--twitching-speed": `${COINS_TWITCHING_SPEED_MS}ms`,
						} as CSSProperties
					}
				>
					{coins.map((src, index) => (
						<div
							key={`reward-coin-${index.toString()}`}
							style={
								{
									"--delay": `-${(COINS_SPEED_MS / coins.length) * index}ms`,
									"--hide-delay": `${COINS_HIDE_DELAY_MS * index}ms`,
									"--twitching-delay": `${Math.floor(
										Math.random() * -COINS_TWITCHING_DELAY_MAX_MS,
									)}ms`,
								} as CSSProperties
							}
						>
							<img src={src} alt={src.replace(/^.+?\/([\w_-]+)[^/]+$/g, "$1")} />
						</div>
					))}
				</div>
				{isSubmitted && (
					<div
						className={styles.content}
						style={
							{
								"--rotate-speed": `${ROTATE_SPEED_MS}ms`,
								"--rotate-count": ROTATE_COUNT,
							} as CSSProperties
						}
					>
						<div className={styles.coin}>
							<div>
								<img src={coinLogoSrc} alt="wallet-logo" />
							</div>
							<span>{coinText}</span>
						</div>
					</div>
				)}
			</div>
			<img className={styles.noise} src={img_Noise} alt="noise" />
		</Modal>
	)
}

export default LootBox
