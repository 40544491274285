import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import messages from "messages/home"

import ExternalLink from "components/ExternalLink"
import { links } from "constants/links"
import TradingVolumeImage from "assets/images/HomePage/TradingVolumeImage.webp"
import styles from "./index.module.scss"

const TradingVolume: FC = () => (
	<div className={styles.tradingVolume}>
		<div className={styles.tradingVolume__content}>
			<div className={styles.tradingVolume__contentWrapper}>
				<h3 className={styles.tradingVolume__headline}><FormattedMessage {...messages.main_tub_name_3} /></h3>

				<p className={styles.tradingVolume__contentText}>
					<FormattedMessage {...messages.main_tub_content_description_3_1} />{" "}
					<ExternalLink
						className={`${styles.mobileApp__button} ${styles.mobileApp__button___disabled}`}
						to={links.coinMarketCapUrl}
					>
						CoinMarketCap
					</ExternalLink>{" "}
					<FormattedMessage {...messages.main_tub_content_description_3_2} />{" "}
					<ExternalLink
						className={`${styles.mobileApp__button} ${styles.mobileApp__button___disabled}`}
						to={links.coinGeckoUrl}
					>
						CoinGecko
					</ExternalLink>{" "}
					<FormattedMessage {...messages.main_tub_content_description_3_3} />
				</p>
			</div>
		</div>

		<img
			className={styles.tradingVolume__svg}
			src={TradingVolumeImage}
			width="280"
			height="280"
			alt="Trading Volume"
		/>
	</div>
)

export default TradingVolume
