import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { FormattedMessage } from "react-intl"

import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"
import messages from "messages/aboutUs"
import AboutUsBannerBackground from "../../../AboutUsBannerBackground"
import styles from "./index.module.scss"

const Banner: FC = () => (
	<section className={styles.banner}>
		<AboutUsBannerBackground />
		<div className={styles.banner__description_btn_wrapper}>
			<p className={styles.banner__description}><FormattedMessage {...messages.banner_description} /></p>

			<InternalLink to={routes.support.request} className={styles.banner__button}>
				<FormattedMessage {...messages.banner_btn} />
			</InternalLink>
		</div>
	</section>
)

export default observer(Banner)
