import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { FormattedMessage } from "react-intl"
import messages from "messages/aboutUs"
// eslint-disable-next-line import/no-extraneous-dependencies
import Spline from "@splinetool/react-spline"

import team from "assets/images/AboutUs/team.png"
// @ts-ignore
import styles from "./index.module.scss"

const Team: FC = () => {
	const list = [
		{
			image: team,
			name: <FormattedMessage {...messages.founder_name} />,
			position: <FormattedMessage {...messages.founder} />,
		},
		{
			image: team,
			name: <FormattedMessage {...messages.cto_name} />,
			position: <FormattedMessage {...messages.cto} />,
		},
		{
			image: team,
			name: <FormattedMessage {...messages.head_name} />,
			position: <FormattedMessage {...messages.head} />,
		},
		{
			image: team,
			name: <FormattedMessage {...messages.tech_lead_name} />,
			position: <FormattedMessage {...messages.tech_lead} />,
		},
	]

	return (
		<section className={styles.team}>
			<h3 className={styles.team__headline}><FormattedMessage {...messages.team} /></h3>

			<div className={styles.team__listWrapper}>
				<ul className={styles.team__list}>
					{list.map((item, index) => (
						<li key={`${item.position}-${index}`} className={styles.team__item}>
							<div className={styles.team__image}>
								<Spline scene="https://prod.spline.design/SUpbmbat9sNAp-jk/scene.splinecode" />
							</div>

							<p className={styles.team__name}>{item.name}</p>
							<p className={styles.team__position}>{item.position}</p>
						</li>
					))}
				</ul>
			</div>
		</section>
	)
}

export default observer(Team)
