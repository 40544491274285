import { defineMessages } from "react-intl"

export default defineMessages({
	page_title: {
		id: "app.components.aboutUs.page_title",
		defaultMessage: "About Us | BTC-Alpha",
	},
	page_description: {
		id: "app.components.aboutUs.page_description",
		defaultMessage:
			"Discover BTC-Alpha, a leading cryptocurrency exchange offering secure and reliable trading services. Learn about our mission, values, and team dedicated to providing top-notch support and innovative solutions in the crypto space. Join us and experience the future of digital finance today.",
	},
	banner_description: {
		id: "app.components.aboutUs.banner_description",
		defaultMessage: "BTC-Alpha Your Secure Crypto Trading Partner",
	},
	banner_btn: {
		id: "app.components.aboutUs.banner_btn",
		defaultMessage: "Contact us",
	},
	safety: {
		id: "app.components.aboutUs.safety",
		defaultMessage: "Prioritizing Your Funds Safety",
	},
	emphasize: {
		id: "app.components.aboutUs.emphasize",
		defaultMessage:
			"We emphasize security to protect your assets, implementing advanced measures to provide a trustworthy trading environment and peace of mind.",
	},
	revolutionize: {
		id: "app.components.aboutUs.revolutionize",
		defaultMessage: "Revolutionize Spending with Crypto",
	},
	cryptocurrency: {
		id: "app.components.aboutUs.cryptocurrency",
		defaultMessage:
			"Our crypto cards offer a seamless way to use your digital assets in everyday transactions, making cryptocurrency more accessible and practical for everyone.",
	},
	history: {
		id: "app.components.aboutUs.history",
		defaultMessage: "History",
	},
	foundation: {
		id: "app.components.aboutUs.foundation",
		defaultMessage: "The foundation of BTC-Alpha: The Rise to Innovation",
	},
	foundation_description: {
		id: "app.components.aboutUs.foundation_description",
		defaultMessage:
			"On November 1, 2016, Vitalii Bodnar opened the doors to the world of cryptocurrencies by launching BTC-Alpha. This was the first step towards creating the platform.",
	},
	success: {
		id: "app.components.aboutUs.success",
		defaultMessage: "Path to Success: Growth and Optimisation",
	},
	success_description: {
		id: "app.components.aboutUs.success_description",
		defaultMessage:
			"BTC-Alpha's first year saw significant growth with platform optimization, a rapidly expanding user base, new features, and a year-end analysis to set future goals.",
	},
	challenges: {
		id: "app.components.aboutUs.challenges",
		defaultMessage: "Challenges and Triumphs: Refusing to Sell Out",
	},
	challenges_description: {
		id: "app.components.aboutUs.challenges_description",
		defaultMessage:
			"In 2018, despite a €20 million offer from the Korean fund NX, Vitaly Bodnar chose independence, focusing on growth, adding new cryptocurrencies, and planning for innovation.",
	},
	strengthening: {
		id: "app.components.aboutUs.strengthening",
		defaultMessage: "Strengthening the Position: Continued Growth",
	},
	strengthening_description: {
		id: "app.components.aboutUs.strengthening_description",
		defaultMessage:
			"BTC-Alpha continues its confident development by enhancing platform performance, expanding its user base and trading opportunities, and refining strategies to achieve even greater success.",
	},
	recognition: {
		id: "app.components.aboutUs.recognition",
		defaultMessage: "Recognition: Ranking in the Top 3 Crypto Exchanges",
	},
	recognition_description: {
		id: "app.components.aboutUs.recognition_description",
		defaultMessage:
			"In 2020, BTC-Alpha gained international recognition as a Top 3 crypto exchange, bolstered its reputation, improved functionality, attracted new users, and planned for future success.",
	},
	answers: {
		id: "app.components.aboutUs.answers",
		defaultMessage: "Challenges and Answers: Defending and Burning Tokens",
	},
	answers_description: {
		id: "app.components.aboutUs.answers_description",
		defaultMessage:
			"In 2021, BTC-Alpha overcame challenges by enhancing ALP Coin’s value, attracting investors, preparing for a U.S. license, and proving its security by repelling a hacker attack.",
	},
	responsibility: {
		id: "app.components.aboutUs.responsibility",
		defaultMessage: "Ethics and Responsibility: Changes in Operations",
	},
	responsibility_description: {
		id: "app.components.aboutUs.responsibility_description",
		defaultMessage:
			"In response to global events, BTC-Alpha ceased operations with Russian clients, focused on supporting existing users, and strengthened international ties while preparing for new challenges.",
	},
	future: {
		id: "app.components.aboutUs.future",
		defaultMessage: "Platform for the Future: Continuous Development",
	},
	future_description: {
		id: "app.components.aboutUs.future_description",
		defaultMessage:
			"In 2023, BTC-Alpha advanced with major updates, new user engagement programs, enhanced trading features, and concluded by planning for 2024.",
	},
	roadmap: {
		id: "app.components.aboutUs.roadmap",
		defaultMessage: "Check Our Roadmap",
	},
	roadmap_description: {
		id: "app.components.aboutUs.roadmap_description",
		defaultMessage:
			"Our Roadmap is always open to feedback and suggestions from our valued customers.",
	},
	q1: {
		id: "app.components.aboutUs.q1",
		defaultMessage: "Q1 2024: Platform Update and New Design",
	},
	q1d1: {
		id: "app.components.aboutUs.q1d1",
		defaultMessage: "Implementation of a new design and improved user interface",
	},
	q1d2: {
		id: "app.components.aboutUs.q1d2",
		defaultMessage: "Increased platform performance",
	},
	q1d3: {
		id: "app.components.aboutUs.q1d3",
		defaultMessage: "Addition of new features to enhance user experience",
	},
	q1d4: {
		id: "app.components.aboutUs.q1d4",
		defaultMessage: "Optimization of security and transaction speed",
	},
	q2: {
		id: "app.components.aboutUs.q2",
		defaultMessage: "Q2 2024: iOS App Launch and New Features",
	},
	q2d1: {
		id: "app.components.aboutUs.q2d1",
		defaultMessage: "Official launch of the BTC-Alpha iOS app",
	},
	q2d2: {
		id: "app.components.aboutUs.q2d2",
		defaultMessage: "Introduction of advanced tools for professional traders",
	},
	q2d3: {
		id: "app.components.aboutUs.q2d3",
		defaultMessage: "Integration of new analytical tools",
	},
	q2d4: {
		id: "app.components.aboutUs.q2d4",
		defaultMessage: "Expanded portfolio management capabilities for mobile users",
	},
	q3: {
		id: "app.components.aboutUs.q3",
		defaultMessage: "Q3 2024: Android App Launch and Feature Expansion",
	},
	q3d1: {
		id: "app.components.aboutUs.q3d1",
		defaultMessage: "Official launch of the BTC-Alpha Android app",
	},
	q3d2: {
		id: "app.components.aboutUs.q3d2",
		defaultMessage: "Listing of new cryptocurrencies on the platform",
	},
	q3d3: {
		id: "app.components.aboutUs.q3d3",
		defaultMessage: "Introduction of enhanced risk management tools",
	},
	q3d4: {
		id: "app.components.aboutUs.q3d4",
		defaultMessage: "Improved API for corporate users",
	},
	q4: {
		id: "app.components.aboutUs.q4",
		defaultMessage: "Q4 2024: Strategic Partnerships and Preparation for 2025",
	},
	q4d1: {
		id: "app.components.aboutUs.q4d1",
		defaultMessage: "Establishment of strategic partnerships with blockchain projects",
	},
	q4d2: {
		id: "app.components.aboutUs.q4d2",
		defaultMessage: "Development of new products for institutional clients",
	},
	q4d3: {
		id: "app.components.aboutUs.q4d3",
		defaultMessage: "Planning service expansion and international growth",
	},
	q4d4: {
		id: "app.components.aboutUs.q4d4",
		defaultMessage: "Preparation for new integrations and enhancements in 2025",
	},
	team: {
		id: "app.components.aboutUs.team",
		defaultMessage: "Meet the Team",
	},
	founder: {
		id: "app.components.aboutUs.founder",
		defaultMessage: "Founder",
	},
	founder_name: {
		id: "app.components.aboutUs.founder_name",
		defaultMessage: "Vitalii B.",
	},
	cto: {
		id: "app.components.aboutUs.cto",
		defaultMessage: "CTO",
	},
	cto_name: {
		id: "app.components.aboutUs.cto_name",
		defaultMessage: "Ihor V.",
	},
	head: {
		id: "app.components.aboutUs.head",
		defaultMessage: "Head of Strategic Partnerships",
	},
	head_name: {
		id: "app.components.aboutUs.head_name",
		defaultMessage: "Andrii F.",
	},
	tech_lead: {
		id: "app.components.aboutUs.tech_lead",
		defaultMessage: "Tech Lead",
	},
	tech_lead_name: {
		id: "app.components.aboutUs.tech_lead_name",
		defaultMessage: "Mykyta S.",
	},
	form_title: {
		id: "app.components.aboutUs.form_title",
		defaultMessage: "Do you have any question?",
	},
	form_description: {
		id: "app.components.aboutUs.form_description",
		defaultMessage: "Text us and we’ll give you answers for any questions :)",
	},
	form_btn_text: {
		id: "app.components.aboutUs.form_btn_text",
		defaultMessage: "Ask a question",
	},
})
