import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import messages from "messages/home"

import LowFeesImage from "assets/images/HomePage/LowFeesImage.webp"
import styles from "./index.module.scss"
import InternalLink from "../../../../InternalLink"
import { routes } from "../../../../../constants/routing"

const LowFees: FC = () => (
	<div className={styles.lowFees}>
		<div className={styles.lowFees__content}>
			<div className={styles.lowFees__contentWrapper}>
				<h3 className={styles.lowFees__headline}><FormattedMessage {...messages.main_tub_name_4} /></h3>

				<p className={styles.lowFees__contentText}>
					<FormattedMessage {...messages.main_tub_content_description_4} />
				</p>
			</div>

			<InternalLink to={routes.tradingFees}>
				<button className={styles.lowFees__button} type="button">
					<FormattedMessage {...messages.learn_more} />
				</button>
			</InternalLink>
		</div>

		<img
			className={styles.lowFees__svg}
			src={LowFeesImage}
			width="280"
			height="280"
			alt="Low fees"
		/>
	</div>
)

export default LowFees
