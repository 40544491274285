import React, { FC, useState } from "react"
import { FormattedMessage } from "react-intl"
import messages from "messages/home"

import Nav from "./Nav"
import styles from "./index.module.scss"
import Support247 from "./Support247"
import TriangleIcon from "../../../../assets/icons/header/TriangleIcon"
import Security360 from "./Security360"
import TradingVolume from "./TradingVolume"
import LowFees from "./LowFees"

const Benefits: FC = () => {
	const [currentItem, setCurrentItem] = useState(0)

	return (
		<section className={styles.benefits}>
			<div className={styles.benefits__container}>
				<h2 className={styles.benefits__headline}><FormattedMessage {...messages.choose} /></h2>

				<Nav setCurrentItem={setCurrentItem} currentItem={currentItem} />

				<div className={styles.benefits__content}>
					<div
						className={`${styles.benefits__contentItem} ${
							currentItem === 0 ? styles.benefits__contentItem___active : ""
						}`}
					>
						<Support247 />
					</div>

					<div
						className={`${styles.benefits__contentItem} ${
							currentItem === 1 ? styles.benefits__contentItem___active : ""
						}`}
					>
						<Security360 />
					</div>

					<div
						className={`${styles.benefits__contentItem} ${
							currentItem === 2 ? styles.benefits__contentItem___active : ""
						}`}
					>
						<TradingVolume />
					</div>

					<div
						className={`${styles.benefits__contentItem} ${
							currentItem === 3 ? styles.benefits__contentItem___active : ""
						}`}
					>
						<LowFees />
					</div>
				</div>

				<div className={styles.benefits__triangle}>
					<TriangleIcon />

					<div className={styles.benefits__circle} />
				</div>
			</div>
		</section>
	)
}

export default Benefits
