import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import messages from "messages/home"

import Support247 from "../../../../../assets/icons/homePage/Support247"
import Security360 from "../../../../../assets/icons/homePage/Security360"
import TradingVolume from "../../../../../assets/icons/homePage/TradingVolume"
import LowFees from "../../../../../assets/icons/homePage/LowFees"
import styles from "../index.module.scss"

const Nav: FC<{ setCurrentItem: (item: number) => void; currentItem: number }> = ({
	setCurrentItem,
	currentItem,
}) => {
	const navItems = [
		{ Icon: Support247, text: <FormattedMessage {...messages.main_tub_name_1} />, id: 0 },
		{ Icon: Security360, text: <FormattedMessage {...messages.main_tub_name_2} />, id: 1 },
		{ Icon: TradingVolume, text: <FormattedMessage {...messages.main_tub_name_3} />, id: 2 },
		{ Icon: LowFees, text: <FormattedMessage {...messages.main_tub_name_4} />, id: 3 },
	]

	return (
		<ul className={styles.benefits__navList}>
			{navItems.map(item => (
				<li className={styles.benefits__navItem} key={`${item.id}-benefit-nav-item`}>
					<button
						className={`${styles.benefits__navButton} ${
							currentItem === item.id ? styles.benefits__navButton___active : ""
						} ${currentItem >= item.id ? styles.benefits__navButton___activeMobile : ""}`}
						type="button"
						onClick={() => setCurrentItem(item.id)}
					>
						<div className={styles.benefits__navIcon}>
							<item.Icon />
						</div>

						<p className={styles.benefits__navText}>{item.text}</p>
					</button>
				</li>
			))}
		</ul>
	)
}

export default Nav
