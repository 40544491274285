import React, { FC, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { FormattedMessage } from "react-intl"
import messages from "messages/aboutUs"
import Slider from "react-slick"

import historyImg2016 from "assets/images/AboutUs/history/h2016.webp"
import historyImg2017 from "assets/images/AboutUs/history/h2017.webp"
import historyImg2018 from "assets/images/AboutUs/history/h2018.webp"
import historyImg2019 from "assets/images/AboutUs/history/h2019.webp"
import historyImg2020 from "assets/images/AboutUs/history/h2020.webp"
import historyImg2021 from "assets/images/AboutUs/history/h2021.webp"
import historyImg2022 from "assets/images/AboutUs/history/h2022.webp"
import historyImg2023 from "assets/images/AboutUs/history/h2023.webp"
import roadmapImg1 from "assets/images/AboutUs/roadmap/roadmap1.webp"
import roadmapImg2 from "assets/images/AboutUs/roadmap/roadmap2.webp"
import roadmapImg3 from "assets/images/AboutUs/roadmap/roadmap3.webp"
import roadmapImg4 from "assets/images/AboutUs/roadmap/roadmap4.webp"
import roadmapImg5 from "assets/images/AboutUs/roadmap/roadmap5.webp"
import CheckQ from "assets/icons/aboutUs/CheckQ"
import ProgressQ from "assets/icons/aboutUs/ProgressQ"
import EmptyQ from "assets/icons/aboutUs/EmptyQ"
import styles from "./index.module.scss"
import { NextArrow, PrevArrow } from "./Arrows"

const History: FC = () => {
	const [nav1, setNav1] = useState(null)
	const [nav2, setNav2] = useState(null)
	let sliderRef1: any = useRef(null)
	let sliderRef2: any = useRef(null)
	let sliderRef3: any = useRef(null)
	const [openIndexes, setOpenIndexes] = useState<number[]>([])
	const toggleList = (index: number) => {
		if (openIndexes.includes(index)) {
			setOpenIndexes(openIndexes.filter(i => i !== index))
		} else {
			setOpenIndexes([...openIndexes, index])
		}
	}
	const lists = [
		{
			icon: <CheckQ />,
			title: <FormattedMessage {...messages.q1} />,
			items: [
				<FormattedMessage {...messages.q1d1} />,
				<FormattedMessage {...messages.q1d2} />,
				<FormattedMessage {...messages.q1d3} />,
				<FormattedMessage {...messages.q1d4} />,
			],
		},
		{
			icon: <CheckQ />,
			title: <FormattedMessage {...messages.q2} />,
			items: [
				<FormattedMessage {...messages.q2d1} />,
				<FormattedMessage {...messages.q2d2} />,
				<FormattedMessage {...messages.q2d3} />,
				<FormattedMessage {...messages.q2d4} />,
			],
		},
		{
			icon: <ProgressQ />,
			title: <FormattedMessage {...messages.q3} />,
			items: [
				<FormattedMessage {...messages.q3d1} />,
				<FormattedMessage {...messages.q3d2} />,
				<FormattedMessage {...messages.q3d3} />,
				<FormattedMessage {...messages.q3d4} />,
			],
		},
		{
			icon: <EmptyQ />,
			title: <FormattedMessage {...messages.q4} />,
			items: [
				<FormattedMessage {...messages.q4d1} />,
				<FormattedMessage {...messages.q4d2} />,
				<FormattedMessage {...messages.q4d3} />,
				<FormattedMessage {...messages.q4d4} />,
			],
		},
	]
	const settings1 = {
		centerMode: true,
		slidesToShow: 5,
		focusOnSelect: true,
		infinite: true,
		slidesToScroll: 1,
		speed: 500,
		autoplay: false,
		autoplaySpeed: 10000,
		pauseOnHover: true,
		initialSlide: 7,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		asNavFor: nav2,
		swipeToSlide: false,
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerPadding: "105px",
				},
			},
		],
	}

	const settings2 = {
		slidesToShow: 1,
		fade: true,
		slidesToScroll: 1,
		speed: 500,
		pauseOnHover: true,
		swipeToSlide: false,
		arrows: false,
		initialSlide: 7,
		customHeight: true,
		asNavFor: nav1,
	}

	const settings3 = {
		dots: true,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToScroll: 1,
		speed: 500,
		pauseOnHover: true,
		swipeToSlide: false,
		arrows: false,
		initialSlide: 5,
	}

	useEffect(() => {
		setNav1(sliderRef1)
		setNav2(sliderRef2)
	}, [])

	const arr = [
		{
			year: 2016,
			title: <FormattedMessage {...messages.foundation} />,
			description: <FormattedMessage {...messages.foundation_description} />,
			image: historyImg2016,
		},
		{
			year: 2017,
			title: <FormattedMessage {...messages.success} />,
			description: <FormattedMessage {...messages.success_description} />,
			image: historyImg2017,
		},
		{
			year: 2018,
			title: <FormattedMessage {...messages.challenges} />,
			description: <FormattedMessage {...messages.challenges_description} />,
			image: historyImg2018,
		},
		{
			year: 2019,
			title: <FormattedMessage {...messages.strengthening} />,
			description: <FormattedMessage {...messages.strengthening_description} />,
			image: historyImg2019,
		},
		{
			year: 2020,
			title: <FormattedMessage {...messages.recognition} />,
			description: <FormattedMessage {...messages.recognition_description} />,
			image: historyImg2020,
		},
		{
			year: 2021,
			title: <FormattedMessage {...messages.answers} />,
			description: <FormattedMessage {...messages.answers_description} />,
			image: historyImg2021,
		},
		{
			year: 2022,
			title: <FormattedMessage {...messages.responsibility} />,
			description: <FormattedMessage {...messages.responsibility_description} />,
			image: historyImg2022,
		},
		{
			year: 2023,
			title: <FormattedMessage {...messages.future} />,
			description: <FormattedMessage {...messages.future_description} />,
			image: historyImg2023,
		},
	]

	return (
		<section className={styles.history}>
			<h3 className={styles.history__headline}>
				<FormattedMessage {...messages.history} />
			</h3>

			<div className={styles.history__sliderContentNavWrapper}>
				<div className={styles.history__sliderContent}>
					{/*@ts-ignore*/}
					<Slider
						ref={slider => {
							sliderRef2 = slider
						}}
						className={styles.history__sliderContentWrapper}
						{...settings2}
					>
						{arr.map(item => (
							<div key={item.year}>
								<div className={styles.history__sliderItemWrapper}>
									<div className={styles.history__sliderItemContent}>
										<p className={styles.history__sliderItemHeadline}>{item.title}</p>

										<p className={styles.history__sliderItemDescription}>{item.description}</p>
									</div>

									<img
										className={styles.history__sliderItemImage}
										width={309}
										height={324}
										src={item.image}
										alt={`${item.year}`}
									/>
								</div>
							</div>
						))}
					</Slider>
				</div>

				<div className={styles.history__sliderContainer}>
					<div className={styles.history__sliderNav}>
						<div className={styles.history__line}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="2000"
								height="2"
								viewBox="0 0 2000 2"
								fill="none"
							>
								<path d="M0.5 1H2000" stroke="#7C59FF" strokeWidth="2" strokeDasharray="10 10" />
							</svg>
						</div>

						{/*@ts-ignore*/}
						<Slider
							ref={slider => {
								sliderRef1 = slider
							}}
							className={styles.history__slider}
							{...settings1}
						>
							{arr.map(item => (
								<div key={item.year}>
									<div className={styles.history__sliderNavItemWrapper}>
										<div className={styles.history__sliderNavItem}>
											<p className={styles.history__sliderNavItemText}>{item.year}</p>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</div>

			<div className={styles.history__content}>
				<div className={styles.history__itemWrapper}>
					<div className={styles.history__itemContent}>
						<p className={styles.history__itemHeadline}>
							<FormattedMessage {...messages.roadmap} />
						</p>

						<p className={styles.history__itemDescription}>
							<FormattedMessage {...messages.roadmap_description} />
						</p>

						<div className={styles.history__qList}>
							{lists.map((list, index) => (
								<div key={index} className={styles.history__qItem}>
									<div className={styles.history__qItemIcon}>{list.icon}</div>
									<div className={styles.history__qTextWrapper} onClick={() => toggleList(index)}>
										<p className={styles.history__qText}>
											{list.title}
											<span
												className={`${styles.arrow_list} ${
													openIndexes.includes(index) ? styles.arrow_open : ""
												}`}
											>
												▼
											</span>
										</p>
										<ul
											className={`${styles.history__qDeskList} ${
												openIndexes.includes(index) ? styles.history__qDeskList_open : ""
											}`}
										>
											{list.items.map((item, itemIndex) => (
												<li key={itemIndex} className={styles.history__qDesk}>
													{item}
												</li>
											))}
										</ul>
									</div>
								</div>
							))}
						</div>
					</div>

					<div className={styles.history__sliderRoadmapWrapper}>
						<Slider
							ref={slider => {
								sliderRef3 = slider
							}}
							className={styles.history__sliderRoadmap}
							{...settings3}
						>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg1}
								alt="roadmap_image1"
							/>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg2}
								alt="roadmap_image2"
							/>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg3}
								alt="roadmap_image3"
							/>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg4}
								alt="roadmap_image4"
							/>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg5}
								alt="roadmap_image5"
							/>
						</Slider>
					</div>
				</div>
			</div>
		</section>
	)
}

export default observer(History)
