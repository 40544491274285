import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import cookies from "js-cookie"

import { routes } from "constants/routing"
import { useMst } from "models/Root"
import { ISecureTokenRes, SecureTokenTypeEnum, SecureTokenVariantEnum } from "types/secureToken"
import { AuthFormFieldsEnum } from "types/auth"
import config from "helpers/config"
import { isLocalhost } from "utils/browser"
import commonMessages from "messages/common"
import homeMessages from "messages/home"
import InternalLink from "components/InternalLink"

import ThirdParty from "../ThirdParty"
import Header from "../Header"
import Input from "../Input"
import Button from "../Button"
import SecureToken from "../../../NewUI/SecureToken"
import CloudflareCaptcha from "../../CloudflareCaptcha"

import styles from "./LoginForm.module.scss"

const LoginForm: React.FC = () => {
	const {
		auth: {
			setLogin,
			setPassword,
			password,
			login,
			onLogin,
			validateLogin,
			errors,
			resetState,
			setIsAuthLoading,
			isAuthLoading,
			hasSecurityCode,
			securityCode,
			setSecurityCode,
			secureTokenType,
			secureTokenError,
			secureTokenDelay,
			secureTokenLeft,
			wizardId,
			onAuthRes,
			isRestoring2FA,
			setWizardStep,
			setCloudflareToken,
			cloudflareToken,
			isErrorCloudflareToken,
			setIsErrorCloudflareToken,
		},
		global: { setIsAuthenticated },
		render: { subAccounts },
	} = useMst()
	const { formatMessage } = useIntl()
	const [isSubAccount, setIsSubAccount] = useState(false)
	const cloudflareRef = React.useRef()

	// @ts-ignore
	const resetAction = () => cloudflareRef.current?.resetAction()

	const handleSubmit = useCallback(async () => {
		const isValid = await validateLogin(formatMessage)

		if (cloudflareToken === "") {
			setIsErrorCloudflareToken(true)

			setTimeout(() => {
				setIsErrorCloudflareToken(false)
			}, 1200)

			return
		}

		if (isValid) {
			await onLogin(resetAction)
		}
	}, [validateLogin, onLogin, formatMessage, cloudflareToken])

	const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		setIsAuthLoading(true)

		handleSubmit()
			.then(() => null)
			.finally(() => setIsAuthLoading(false))
	}

	const handleLoginSuccess = (res: ISecureTokenRes) => {
		if (res.done?.token) {
			if (isLocalhost) {
				cookies.set(config.sessionCookieName, res.done.token)
			}

			setIsAuthenticated(true)
		}
	}

	const handleSetSubAccount = useCallback(() => {
		if (!isSubAccount) setIsSubAccount(true)
	}, [isSubAccount])

	const handleSetLogin = useCallback(() => {
		if (isSubAccount) setIsSubAccount(false)
	}, [isSubAccount])

	useEffect(() => () => resetState(), [])

	return (
		<div className={styles.loginForm}>
			<div className={styles.loginForm__wrapper}>
				{secureTokenType ? (
					<SecureToken
						requestUrl="web/profile/auth"
						resendRequestUrl="web/resend-pincode"
						onResponse={onAuthRes}
						onSuccess={handleLoginSuccess}
						body={{ email: login, password: password, wizard_id: wizardId }}
						shouldAutoFocus
						error={secureTokenError}
						delay={secureTokenDelay}
						emailAssetName={login}
						type={secureTokenType as SecureTokenTypeEnum}
						pincodeTriesleft={secureTokenLeft}
						variant={SecureTokenVariantEnum.BUTTON}
						fullSize={!isRestoring2FA}
						setWizardStep={setWizardStep}
						disabledLabel
					/>
				) : (
					<>
						<Header
							headline={formatMessage(commonMessages.welcome_back)}
							description={formatMessage(commonMessages.new_one)}
							link={
								<InternalLink
									className={styles.loginForm__descriptionLink}
									to={routes.register.root}
								>
									{formatMessage(commonMessages.create_account)}
								</InternalLink>
							}
						/>

						<form onSubmit={handleFormSubmit}>
							<div className={styles.loginForm__emailSubWrapper}>
								<div className={styles.loginForm__toggle}>
									<button
										type="button"
										onClick={handleSetLogin}
										className={`${styles.loginForm__toggleButton} ${
											!isSubAccount ? styles.loginForm__toggleButton___active : ""
										}`}
									>
										{formatMessage(commonMessages.email)}
									</button>

									{subAccounts ? (
										<button
											type="button"
											onClick={handleSetSubAccount}
											className={`${styles.loginForm__toggleButton} ${
												isSubAccount ? styles.loginForm__toggleButton___active : ""
											}`}
										>
											{formatMessage(commonMessages.sub_account)}
										</button>
									) : null}
								</div>

								<div className={styles.loginForm__emailWrapper}>
									<Input
										id="login-email"
										placeholder={`${isSubAccount ? formatMessage(commonMessages.username) : formatMessage(homeMessages.email)}`}
										value={login}
										handleChange={setLogin}
										autoFocus
										autoComplete="on"
										name={AuthFormFieldsEnum.LOGIN}
										error={errors.login}
									/>
								</div>
							</div>

							<div className={styles.loginForm__passwordWrapper}>
								<Input
									id="login-password"
									label={formatMessage(commonMessages.password)}
									type="password"
									placeholder={formatMessage(commonMessages.enter_password)}
									name={AuthFormFieldsEnum.PASSWORD}
									autoComplete="on"
									value={password}
									handleChange={setPassword}
									error={errors.password}
									labelRightComponent={
										<InternalLink
											className={styles.loginForm__forgotPasswordLink}
											to={routes.resetPassword}
										>
											{formatMessage(commonMessages.forgot_password)}
										</InternalLink>
									}
								/>
							</div>

							{hasSecurityCode ? (
								<div className={styles.loginForm__passwordWrapper}>
									<Input
										id="login-security-code"
										label={formatMessage(commonMessages.security_code)}
										type="text"
										placeholder={formatMessage(commonMessages.security_code)}
										name={AuthFormFieldsEnum.SECURITY_CODE}
										autoComplete="off"
										value={securityCode}
										handleChange={setSecurityCode}
										error={errors.securityCode}
									/>
								</div>
							) : null}

							<div
								className={`${styles.loginForm__captchaWrapper} ${
									cloudflareToken !== "" ? styles.loginForm__captchaWrapper___closed : ""
								}`}
							>
								<CloudflareCaptcha
									ref={cloudflareRef}
									setToken={setCloudflareToken}
									theme="light"
									isError={isErrorCloudflareToken}
								/>
							</div>

							<div className={styles.loginForm__buttonWrapper}>
								<Button isLoading={isAuthLoading} type="submit">
									{formatMessage(commonMessages.login)}
								</Button>
							</div>
						</form>

						<ThirdParty lineText={formatMessage(commonMessages.login_or)} />
					</>
				)}
			</div>
		</div>
	)
}

export default observer(LoginForm)
