import React, { FC, useEffect, useMemo, useState } from "react"
import OtpInput from "react-otp-input"
import { useIntl } from "react-intl"
import dayjs from "dayjs"

import messages from "messages/common"
import Back from "assets/icons/auth/Back"
import styles from "./PinCode.module.scss"
import Header from "../Header"
import DelayText from "./DelayText"
import Button from "../Button"

const PinCode: FC<{
	onChange: (otp: string) => void
	value: string
	isError?: boolean
	triesLeft: number
	resendAction?: () => void
	sendAction?: () => void
	backAction?: () => void
	resetAction: () => void
	pinCodeTimeout: string
	pinCodeGeneratedAt: string
}> = ({
	value,
	onChange,
	isError = false,
	triesLeft,
	resendAction,
	sendAction,
	pinCodeTimeout,
	pinCodeGeneratedAt,
	backAction,
	resetAction,
}) => {
	const { formatMessage } = useIntl()
	const [delay, setDelay] = useState(120)

	useEffect(() => {
		const interval = setInterval(() => {
			if (pinCodeTimeout === "" || pinCodeGeneratedAt === "") {
				setDelay(60)

				return
			}

			const now = dayjs()
			const timeout = dayjs(pinCodeTimeout)

			setDelay(timeout.diff(now, "second"))

			if (timeout.diff(now, "second") <= 0) {
				clearInterval(interval)
				setDelay(0)
			}
		}, 1000)

		return () => clearInterval(interval)
	}, [pinCodeTimeout, pinCodeGeneratedAt])

	useEffect(() => () => resetAction(), [])

	return (
		<div className={styles.pinCode}>
			<Header
				headline={formatMessage(messages.verification_email)}
				description={formatMessage(messages.verification_email_description)}
			/>

			<div className={styles.pinCode__wrapper}>
				<OtpInput
					onChange={onChange}
					value={value}
					hasErrored={isError}
					numInputs={6}
					inputStyle={{ width: "100%" }}
					containerStyle="otp-container"
					isInputNum
					errorStyle="otp-error"
					shouldAutoFocus
				/>

				<DelayText delayTime={delay} triesLeft={triesLeft} resendRequest={resendAction} />

				<div className={styles.pinCode__buttons}>
					<Button disabled={value.length !== 6} onClick={sendAction}>
						{formatMessage(messages.submit)}
					</Button>

					<Button color="transparent" onClick={backAction}>
						<Back />

						<span>{formatMessage(messages.back_btn)}</span>
					</Button>
				</div>
			</div>
		</div>
	)
}

export default PinCode
