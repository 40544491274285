import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import messages from "messages/home"

import InternalLink from "components/InternalLink"
import { routes } from "constants/routing"
import { useMst } from "models/Root"
import Support247Image from "assets/images/HomePage/Support247Image.webp"
import styles from "./index.module.scss"

const Support247: FC = () => {
	const {
		render: { supportCenter },
	} = useMst()

	return (
		<div className={styles.support}>
			<div className={styles.support__content}>
				<div className={styles.support__contentWrapper}>
					<h3 className={styles.support__headline}><FormattedMessage {...messages.main_tub_content_title_1} /></h3>

					<p className={styles.support__contentText}>
						<FormattedMessage {...messages.main_tub_content_description_1} />
					</p>
				</div>

				{supportCenter ? (
					<InternalLink to={routes.support.root}>
						<button className={styles.support__button} type="button">
							<FormattedMessage {...messages.learn_more} />
						</button>
					</InternalLink>
				) : null}
			</div>

			<img
				className={styles.support__svg}
				src={Support247Image}
				width="280"
				height="280"
				alt="Support 24/7"
			/>
		</div>
	)
}

export default Support247
